<template>
    <!-- 考勤登记 -->
    <div class="attendance-list">
        <div class="manage-wrapper">
            <div class="table-wrapper">
                <div class="table-title-wrapper flex-align-between">
                    <h2 class="table-title">
                        <img src="../../assets/images/home/attendance.png" alt="" class="title-icon">考勤管理</h2>
                    <!-- <button class="check-in csp" @click="openDialog()">
                        <i class="el-icon-plus"></i>考勤登记
                    </button> -->
                </div>

                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="序号" style="background:#2373EC" align="center"
                            :index="indexMethod">
                        </el-table-column>
                        <el-table-column prop="date" label="日期" align="center">
                            <template slot-scope="scope">
                                {{dayjs(scope.row.date).format('YYYY-MM-DD')}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="time" label="签到时间" align="center"></el-table-column>
                        <el-table-column prop="placeName" label="签到地点" align="center"></el-table-column>
                        <el-table-column prop="state" label="状态" align="center">
                            <template slot-scope="scope">
                                {{attendanceState[scope.row.state]}}
                            </template>
                        </el-table-column>

                        <el-table-column prop="fileUrl" label="附件" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" :disabled="!scope.row.fileUrl"
                                    @click="previewImage(scope.row.fileUrl)">
                                    {{scope.row.fileUrl || '无'}}</el-button>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" :disabled="supplySignInState(scope.row)"
                                    @click="openDialog(scope.row)">
                                    补签</el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" @pagination="handlePageChange">
                    </pagination>
                </div>
            </div>
        </div>
        <el-dialog title="考勤登记" :visible.sync="checkInDialog" width="700px" destroy-on-close @close="closeDialog">
            <div slot="title" class="dialog-title">
                <span>考勤登记</span>
                <span class="dialog-title-time">{{dayjs(this.currentAttendance.date).format('YYYY-MM-DD')}}</span>
            </div>
            <div class="qrcode-box">
                <div class="qrcode" id="qrcode" ref="qrcode"></div>
                <p class="qr-tips">打开双优云桥app扫码登记</p>
            </div>
            <el-form ref="createForm" :model="createForm" label-width="120px">
                <el-form-item label="上传图片附件：" prop="identifier">
                    <upload @uploadFile="handleFileChange" :name="'identifier'" :text="'上传附件'" :list="echoImageList" />
                </el-form-item>
                <el-form-item class="btns-box" label-width="0px">
                    <el-button class="submit btn" @click="coverTips">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    import upload from '../manager/components/upload-file.vue';
    export default {
        data() {
            return {
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                tableData: [],
                checkInDialog: false,
                createForm: {
                    identifier: '',
                    fileName: '',
                },
                attendanceState: {
                    0: '待签到',
                    1: '已签到',
                    2: '补签到',
                },
                currentAttendance: {},
                today: this.dayjs().format('YYYY-MM-DD'),
                isSupplySignIn: false, // 当前点击是否为补签
                echoImageList: [], // 图片回显列表
                // showViewer: false, // 图片预览
                imageList: [],
            }
        },
        components: {
            upload,
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            })
        },
        methods: {
            // 上传文件返回
            handleFileChange(data) {
                // console.log(data);
                this.createForm.identifier = data.fileMD5List[0];
                if (JSON.stringify(data.fileList) == '[]') return;
                this.createForm.fileName = data.fileList[0].name
            },
            // 序号
            indexMethod(index) {
                Common.tableIndexMethod(index, this.pages.currentPage, this.pages.pageSize)
            },
            // 预览图片
            previewImage(url) {
                this.imageList = [];
                this.imageList.push(this.downloadURL + url);
                this.$previewImgs(this.imageList)
            },
            // 补签按钮状态
            supplySignInState(row) {
                let isToday = this.today == this.dayjs(row.date).format('YYYY-MM-DD');
                // 如果 是 当天 ，补签状态禁用
                // 如果 不是 当天 ，状态为 1 补签禁用
                // 如果 不是 当天 ，状态为 0||2 补签可用 
                if (isToday) {
                    return true;
                }
                if (!isToday) {
                    return row.state == 1 ? true : false
                }
            },
            // 打开签到弹窗
            openDialog(item) {
                this.checkInDialog = true
                this.getAttendanceOne(item);
                this.isSupplySignIn = item ? true : false
                this.qrcode();
            },
            // 关闭签到弹窗
            closeDialog() {
                this.currentAttendance = {};
                this.echoImageList = [];
                Vue.set(this.createForm, 'fileName', undefined);
                Vue.set(this.createForm, 'identifier', undefined);
            },
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getAttendanceByTeacher();
            },
            // 获取签到列表
            async getAttendanceByTeacher() {
                let params = {
                    projectId: this.currentProject.id,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                let resData = await this.$Api.Project.getAttendanceByTeacher(params);
                // console.log(resData);
                if (JSON.stringify(resData.data.records) == '[]' || !resData) {
                    this.tableData = [];
                    return;
                }
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
            },
            // 获取选中的某一条信息
            async getAttendanceOne(item) {
                let params = {
                    projectId: this.currentProject.id,
                }
                params.id = item ? item.id : null;
                let resData = await this.$Api.Project.getAttendanceOne(params);
                this.currentAttendance = resData.data
                if (this.currentAttendance != 1 && this.currentAttendance.fileUrl) {
                    this.echoImageList.push({
                        identifier: this.currentAttendance.fileUrl,
                        filename: this.currentAttendance.fileUrlName
                    })
                }

            },
            // 绘制二维码
            qrcode() {
                this.$nextTick(() => {
                    let that = this;
                    let qrcode = new that.QRCode('qrcode', {
                        width: 140, // 设置宽度，单位像素
                        height: 140, // 设置高度，单位像素
                        text: {} // 设置二维码内容或跳转地址
                    })
                })
            },
            // 当日二次上传提示
            coverTips() {
                let isToday = this.today == this.dayjs(this.currentAttendance.date).format('YYYY-MM-DD');
                if (this.currentAttendance.time && isToday) {
                    this.$confirm('您今日已提交，如再次提交原有数据会被覆盖', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.submitAttendance();
                        return;
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消提交'
                        });
                        return;
                    });
                } else {
                    this.submitAttendance();
                }
            },
            // 提交签到
            async submitAttendance() {
                let params = {
                    id: this.currentAttendance.id,
                    date: this.dayjs(this.currentAttendance.date).format('YYYY-MM-DD HH:mm:ss'),
                    time: this.dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    projectId: this.currentProject.id,
                    longitude: 0,
                    latitude: 0,
                    fileUrl: this.createForm.identifier,
                    fileUrlName: this.createForm.fileName,
                    state: this.isSupplySignIn ? 2 : 1
                }
                // console.log(params);
                let resData = await this.$Api.Project.editAattendance(params);
                // console.log(resData);
                if (resData.data.code == 200 && resData.data.data) {
                    let msg = params.state == 2 ? '补签' : '签到'
                    this.$message({
                        message: `${msg}成功`,
                        type: 'success'
                    });
                    this.checkInDialog = false
                    this.getAttendanceByTeacher();
                } else {
                    this.$message({
                        message: `签到失败，请联系管理员 ${resData.msg}`,
                        type: 'error'
                    });
                }
            }
        },
        mounted() {
            this.getAttendanceByTeacher();
        }
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .attendance-list {
        .table-wrapper {
            border-radius: 8px;
            padding-top: 10px;
            padding: 0 26px 24px;
            box-shadow: 0px 2px 10px 0px #f5f8fa;
        }

        .table-title {
            line-height: 68px;
            font-size: 16px;
        }

        .title-icon {
            width: 17px;
            height: 18px;
            margin-right: 9px;
            vertical-align: -4px;
        }

        .check-in {
            background-color: #249BFD;
            color: #fff;
            border-radius: 25px;
            line-height: 33px;
            padding: 0 15px;

            .el-icon-plus {
                margin-right: 5px;
            }

            &:hover {
                background-color: rgba(36, 155, 253, .8);
            }
        }

        /deep/ .el-dialog {
            border-radius: 10px;

            .el-dialog__header {
                border-bottom: 1px solid #E4E4E4;
                padding: 15px 31px 10px;
            }

            .el-dialog__headerbtn {
                top: 15px;
            }

            .dialog-title {
                font-size: 16px;
                color: #333;
                position: relative;

                &::before {
                    content: '';
                    width: 5px;
                    height: 17px;
                    background-color: #01A2FD;
                    display: inline-block;
                    margin-right: 14px;
                    vertical-align: -3px;
                    border-radius: 3px;
                }

                .dialog-title-time {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .qrcode {
                img {
                    margin: 0 auto;
                }
            }

            .qr-tips {
                color: #01A2FD;
                font-size: 15px;
                text-align: center;
                margin: 16px 0;
            }

            .upload-file {
                .el-button {
                    border: 1px solid #01A2FD;
                    color: #01A2FD;
                }
            }

            .el-form-item__label {
                color: #333;
                font-weight: 600;
            }

            .btns-box {
                text-align: center;
            }

            .submit {
                background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
                border-radius: 25px;
                color: #fff;
                line-height: 35px;
                padding: 0 63px;
                cursor: pointer;
                border: 0;

                &:hover {
                    background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
                }
            }
        }

        /deep/.el-icon-circle-close {
            color: #fff;
        }

        /deep/.el-button--small {
            span {
                display: block;
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis
            }
        }

        @media screen and (min-width:1250px) {
            .table-wrapper {
                padding: 0 43px 24px;
            }
        }
    }
</style>